<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import customTable from "@/components/paginate-table.vue";
import mobileView from "../../../components/mobile-view.vue";
import { detectScreen } from "@/_helpers";

/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader, customTable, mobileView },
  page: {
    title: "Data Pakaian",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          href: "#/",
        },
        {
          text: "Produk",
        },
        {
          text: this.$route.name,
          active: true,
        },
      ],
      screenWidth: detectScreen(),
      dataKey: "cloth",
      params: [
        {
          'name': 'order',
          'value': 'sort'
        }
      ],
      apiUrl: process.env.VUE_APP_API_BASE_URL + "clothes",
      columns: [
        {
          prop: "cloth_category.name",
          label: "Nama",
          width: "180",
          sortable: true,
        },
        {
          prop: "status",
          label: "Status",
          width: "220",
          status: true,
          center: true,
        },
        {
          prop: "created_at",
          label: "Tgl Dibuat",
          width: "220",
          center: true,
          date: true,
        },
        {
          prop: "updated_at",
          label: "Tgl Diperbarui",
          width: "220",
          center: true,
          date: true,
        },
        {
          prop: "created_by.name",
          label: "Dibuat Oleh",
          width: "150",
          user: true,
          center: true,
        },
        {
          prop: "updated_by.name",
          label: "Diperbarui Oleh",
          width: "150",
          user: true,
          center: true,
        },
      ],
    };
  },
  methods: {
    onScreenResize() {
      this.screenWidth = detectScreen();
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout v-if="screenWidth >= 860">
    <PageHeader :title="$route.name" :items="items" />
    <b-card>
      <b-card-body>
        <custom-table
          ref="dataTable"
          :with-add="$store.getters['account/accountData'].role == 'owner' ? true : false"
          :with-form-add="$store.getters['account/accountData'].role == 'owner' ? true : false"
          :default-params="params"
          :form-url="'/produk/tambah'"
          :with-filter-name="true"
          :enabled-edit-sort="true"
          :with-fixed="true"
          :with-delete="true"
          :with-print="false"
          :with-edit="false"
          :data-url="apiUrl"
          :data-key="dataKey"
          :data-columns="columns"
          filter-with="name"
        ></custom-table>
      </b-card-body>
    </b-card>
  </Layout>

  <mobileView
    v-else
    ref="dataTable"
    title-filter="Filter Berdasarkan Nama Pakaian"
    :with-filter-text="true"
    :form-url="'/produk/tambah'"
    :data-url="apiUrl"
    :card-default="false"
    :card-produk="true"
    skeleton-height="200px"
    skeleton-radius="5px"
  ></mobileView>
</template>
